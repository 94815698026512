import React, { useState } from 'react'

const Video = ({ srcURL, title, ...props }) => {
  const [acceptYoutube, setAcceptYoutube] = useState(false)

  const handleYoutubeAgreement = () => {
    setAcceptYoutube(true)
  }

  return (
    <div className="video">
      {!acceptYoutube ? (
        <p className="video-notice">
          Mit dem Laden des Videos akzeptieren Sie die{' '}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            Datenschutzerklärung von YouTube
          </a>
          .<br />
          <button
            className="btn btn-secondary"
            onClick={handleYoutubeAgreement}
          >
            Inhalte von YouTube laden
          </button>
        </p>
      ) : (
        <iframe
          src={srcURL}
          title={title}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          {...props}
        />
      )}
    </div>
  )
}

export default Video
