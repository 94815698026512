import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';
import SlideShow from '../components/slideShow';
import Hero from '../components/hero';
import Video from '../components/video';

const IndexPage = () => {
  const { allFile: { nodes } } = useStaticQuery(graphql`{
    allFile(
      sort: {fields: name, order: DESC}
      filter: {relativeDirectory: {eq: "slides"}}
    ) {
      nodes {
        id
        name
        childImageSharp {
          gatsbyImageData(quality: 65, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }`);

  const slides = [
    {
      title: 'Einzigartige Spielobjekte',
      text: 'Unsere Kunstwerke sind wahre Unikate!',
    },
    {
      title: 'Regionaler Bezug',
      text: 'Geschichtliche, kulturelle oder geologische Besonderheiten sind ein wichtiger Faktor bei unserer Ideenfindung.',
    },
    {
      title: 'Nachhaltige Bauweise',
      text: 'Für unsere Spielskulpturen verwenden wir Holz aus zertifizierten europäischen Quellen.',
    }
  ].map((slide, i) => ({ ...slide, file: nodes[i] }));

  return (
    <Layout>
      <Seo title="Spielplätze, Wanderwege, Skulpturen" />

      <SlideShow slides={ slides } />

      <Hero>
        <h1>Der Spielplatz ein Kult- und Kunstplatz</h1>
        <p className="lead">Wir entwerfen und verwirklichen außergewöhnliche bespielbare Skulpturen für ein spielerisches Abenteuer.</p>
        <p>Unsere Spielobjekte sind einzigartig und immer für den jeweiligen Platz und dessen Umfeld bestimmt. Wir verwenden hochwertige Materialien und arbeiten als Familienbetrieb in Handarbeit.</p>
        <p>Neben Spielplätzen bieten wir auch Kunstobjekte für Stadt und Landschaft.</p>
        <Link to="/spielplaetze" className="btn btn-primary me-2">Spielplätze</Link>
        <Link to="/kunst-in-der-landschaft" className="btn btn-primary">Kunst in der Landschaft</Link>
      </Hero>


      <div className="container-xl py-4">

        <div className="row mb-5">
          <div className="col-sm">
            <Video
              srcURL="https://www.youtube.com/embed/MkqgKFi-FTc"
              title="Projekt Spielart Lonetal"
              width="100%"
              height="600"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm">
            <div className="card">
              <StaticImage
                src="../images/content/regionaler-bezug.jpg"
                alt="Regionaler Bezug"
                height={ 200 }
                className="card-img-top"
              />
              <div className="card-body">
                <h4 className="card-title">Regionaler Bezug</h4>
                <p className="card-text">Wir entwickeln unsere Ideen durch persönliche Eindrücke vor Ort. Geschichtliche, kulturelle oder geologische Besonderheiten sind ein wichtiger Faktor bei unserer Ideenfindung.</p>
                <Link to="/ueber-uns" className="btn btn-secondary">Unsere Ideenfindung</Link>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="card">
              <StaticImage
                src="../images/content/klettereinbauten.jpg"
                alt="Spielanreiz"
                height={ 200 }
                className="card-img-top"
              />
              <div className="card-body">
                <h4 className="card-title">Individuelle Gestaltung</h4>
                <p className="card-text">Unsere Kunstwerke entstehen ausschließlich für den bestimmten Platz und dessen Umfeld. Jedes Projekt Spielart ist ein wahres Unikat!</p>
                <Link to="/ueber-uns" className="btn btn-secondary">Ein Kunstwerk entsteht</Link>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="card">
              <StaticImage
                src="../images/content/materialien.jpg"
                alt="Hochwertige Materialien"
                height={ 200 }
                className="card-img-top"
              />
              <div className="card-body">
                <h4 className="card-title">Nachhaltige Bauweise</h4>
                <p className="card-text">In erster Linie verwenden wir für unsere Spielskulpturen bestes Eichenholz oder splintfreies Robinienholz aus zertifizierten europäischen Quellen.</p>
                <Link to="/ueber-uns" className="btn btn-secondary">Unsere Materialien</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
